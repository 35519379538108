import React from 'react';

import { Link } from "react-router-dom";

import { Menu } from 'antd';
import {
    ContainerOutlined,
    SnippetsOutlined,
    FileImageOutlined,
} from '@ant-design/icons';




export default class Mobilemenu extends React.Component {
  state = {
    collapsed: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <div style={{ backgroundColor:'#000'}}>
        <Menu
          theme="dark"
          inlineCollapsed={this.state.collapsed}
        >
          <Menu.Item>
              <Link to="/app/profile/">
          <FileImageOutlined />
            <span>Slider</span></Link>
          </Menu.Item>
          <Menu.Item>
          <Link to="/app/products/">
          <ContainerOutlined />
            <span>Products</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
          <Link to="/app/news/">
            <SnippetsOutlined />
            <span>News</span>
            </Link>
          </Menu.Item>
   
      
        </Menu>
      </div>
    );
  }
}