import React, { useContext, useState, useEffect, useRef } from 'react';

import { useHistory } from "react-router-dom";
import { getUser, logout } from "../services/auth";

import Myheader from "./myheader"

import dateFormat from 'dateformat';
import 'antd/dist/antd.css';
import { Table, Input, Popconfirm, Form, Modal, Upload, message, Spin, Tooltip} from 'antd';
import { Paper, Grid, Button, Box, Typography, IconButton } from '@material-ui/core';

import marked from 'marked';
import Editor from 'for-editor-herb';


import DeleteIcon from '@material-ui/icons/Delete';
import {
  SnippetsOutlined,
} from '@ant-design/icons';

// const mdParser = new MarkdownIt({
//   html:         true,        // Enable HTML tags in source
//   xhtmlOut:     false,        // Use '/' to close single tags (<br />).
//                               // This is only for full CommonMark compatibility.
//   breaks:       true,        // Convert '\n' in paragraphs into <br>
//   langPrefix:   'language-',  // CSS language prefix for fenced blocks. Can be
//                               // useful for external highlighters.
//   linkify:      false,        // Autoconvert URL-like text to links

//   // Enable some language-neutral replacement + quotes beautification
//   typographer:  false,

//   // Double + single quotes replacement pairs, when typographer enabled,
//   // and smartquotes on. Could be either a String or an Array.
//   //
//   // For example, you can use '«»„“' for Russian, '„“‚‘' for German,
//   // and ['«\xA0', '\xA0»', '‹\xA0', '\xA0›'] for French (including nbsp).
//   quotes: '“”‘’',

//   // Highlighter function. Should return escaped HTML,
//   // or '' if the source string is not changed and should be escaped externally.
//   // If result starts with <pre... internal wrapper is skipped.
//   highlight: function (/*str, lang*/) { return ''; }
// });


const Hljs = require('highlight.js');

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  // return isJpgOrPng && isLt2M;
 return true;
}


const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
    
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


export default class News extends React.Component {
  constructor(props) {
    super(props);
    this.$vm = React.createRef();
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'key',
        width: '40px',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.key - b.key,
   
      },
      {
        title: 'Date',
        dataIndex: 'date',
        width: '120px',
        sorter: (a, b) => a.date - b.date,
   
      },
     
      {
        title: 'Title',
        dataIndex: 'title',
        editable: true,
    sorter: (a, b) => { return a.title.localeCompare(b.title)},
   
      },
      
      {
        title: 'Image',
        dataIndex: 'image',
        width: '70px',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
     
              <Button onClick={() =>this.showModal(record.key)}><img src={text} alt="news" style={{ width:40, height:40 }} /></Button>
          
            
          ) : null,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '100px',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            
            <Typography variant="body2" onClick={() =>this.showModald(record.key)} color='primary' style={{ cursor:'pointer'}}>Description</Typography>
          ) : null,
          
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: '80px',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
            <IconButton aria-label="delete" color="secondary">
  <DeleteIcon />
</IconButton>
          </Popconfirm>
          ) : null,
          
      },
    ];
    this.state = {
      dataSource: [],
      count: 2,
      id: 0,
      loading: "false",
      visible: false,
      visibled: false,
      loading1: false,
      loadingimage: false,
      addnew: "Add New",
      value: '',
      valuehtml: '',
    };
  }
  addImg= async ($file) => {
    try {
      let form = new FormData();
      form.append('file', $file);
     
      await fetch('https://cors-anywhere.herokuapp.com/https://apiv2.chennaihost.com/api/media_objects', {
        method: "post",
          headers: {
              'Authorization': 'Bearer ' + getUser().token,
          },
          //make sure to serialize your JSON body
          body: form,
          }
      )
      .then((response) => {
        
        if(response.status === 401)
        {
          logout(() => useHistory().push(`/`));
          
        }
        else
        {
          return response.json();
        }
      })
      .then((data) => {

        let url = "https://apiv2.chennaihost.com/" + data.contentUrl
       
        this.$vm.current.$img2Url($file.name, url)
        
      })
      // this.loadphotos();
        }
        catch (error) {
         //alert(error);
         console.log(error);
        }

    
  }
  loadphotos = async () =>
  {
  
   try {
     await fetch('https://apiv2.chennaihost.com/api/blogs', {
       method: 'GET',
       headers: {
         'Authorization': 'Bearer ' + getUser().token,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
       },
     })
     .then((response) => {
       
      if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
     })
     .then((data) => {
      
      this.setState({loading:"true"})
       this.setState({dataSource: data.map(item => ({
         ...item,
         key: item.id,
         date: dateFormat(item.ndate, "dd-mm-yyyy"),
         title: item.title,
         image: item.url,
       })), count:data.length});
     })
   }
   catch (error) {
    // alert(error);
    console.log(error);
   }
  }
   componentDidMount = ()=>{
     this.loadphotos();
     Hljs.registerLanguage('css', require('highlight.js/lib/languages/css'))
        Hljs.registerLanguage('json', require('highlight.js/lib/languages/json'))
        Hljs.registerLanguage('less', require('highlight.js/lib/languages/less'))
        Hljs.registerLanguage('scss', require('highlight.js/lib/languages/scss'))
        Hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'))
        Hljs.registerLanguage('typescript', require('highlight.js/lib/languages/typescript'))
        Hljs.registerLanguage('go', require('highlight.js/lib/languages/go'))
  
 }
  handleDelete = key => {
    
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter(item => item.key !== key),
    });

    let deleteapi='https://apiv2.chennaihost.com/api/blogs/' + key;
    fetch(
      deleteapi,{
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + getUser().token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        }
    );

  };

  

  handleAdd = async () => {
    this.setState({addnew: "Adding .. "})
    try {
      let collection={};
      collection.title = "click to edit";
      collection.url = "https://www.chennaihost.com/images/noimage.jpg";
      collection.ndate = new Date();
      await fetch('https://apiv2.chennaihost.com/api/blogs', {
        method: "post",
          headers: {
              'Authorization': 'Bearer ' + getUser().token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          //make sure to serialize your JSON body
          body: JSON.stringify(collection),
          }
      )
      .then((response) => {
        if(response.status === 401)
        {
          logout(() => useHistory().push(`/`));
          
        }
        else
        {
          return response.json();
        }
      })
      .then((data) => {
        const { count, dataSource } = this.state;
        const newData = {
          key: data.id,
          title: data.title,
          image: data.url,
          date: dateFormat(data.ndate, "dd-mm-yyyy"),
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
          addnew: "Add New",
        });
    
        
      })
      // this.loadphotos();
        }
        catch (error) {
         //alert(error);
         console.log(error);
        }

   
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row }); 
    this.setState({
      dataSource: newData,
    });
    const newitems = newData[index];
    try {
      let collection={};
      collection.title = newitems.title;
      fetch('https://apiv2.chennaihost.com/api/blogs/'+newitems.key, {
        method: "put",
          headers: {
              'Authorization': 'Bearer ' + getUser().token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          //make sure to serialize your JSON body
          body: JSON.stringify(collection),
          }
      )
      .then((response) => {
        if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
      })
      .then((data) => {
       
    
        
      });
      // this.loadphotos();
        }
        catch (error) {
         //alert(error);
         console.log(error);
        }
    //alert (newitems.name);
  };
// modal
showModal = key => {
  
  this.setState({
    visible: true,
    loadingimage: true,
    id: key,
  });
  let getapi='https://apiv2.chennaihost.com/api/blogs/' + key;
    fetch(
      getapi,{
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + getUser().token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          
          if(response.status === 401)
          {
            logout(() => useHistory().push(`/`));
            
          }
          else
          {
            return response.json();
          }
        })
        .then((data) => {

         this.setState({imageUrl:data.url, loadingimage:false})
        
        })

};

handleOk = e => {
 
  this.setState({
    visible: false,
  });
};

handleCancel = e => {

  this.setState({
    visible: false,
  });
};
showModald = key => {
  
  this.setState({
    visibled: true,
    loadingimage: true,
    id: key,
  });
  let getapi='https://apiv2.chennaihost.com/api/blogs/' + key;
    fetch(
      getapi,{
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + getUser().token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          
          if(response.status === 401)
          {
            logout(() => useHistory().push(`/`));
            
          }
          else
          {
            return response.json();
          }
        })
        .then((data) => {

         this.setState({value:data.mdvalue, loadingimage:false})
        
        })

};
handleOkd = e => {
  this.setState({
    visibled: false,
    loadingimage: false,
  });
  this.postdescription();
};
handleCanceld = e => {
  console.log(e);
  this.setState({
    visibled: false,
  });
};
handleChange = info => {

  if (info.file.status === 'uploading') {
    this.setState({ loadingimage: true });
    return;
  }
  if (info.file.status === 'done') {
    this.postphotos(info.file.response.contentUrl);
  }
};

postphotos = async(url) => {
   
  try {
  
let collection={};
collection.url = "https://apiv2.chennaihost.com/" + url;
await fetch('https://apiv2.chennaihost.com/api/blogs/'+this.state.id, {
  method: "put",
    headers: {
        'Authorization': 'Bearer ' + getUser().token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    //make sure to serialize your JSON body
    body: JSON.stringify(collection),
    }
)
.then((response) => {
  if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
})
.then((data) => {
  let row={};
  row.key = this.state.id;
  row.image = data.url;
  row.name = data.name;
  const newData = [...this.state.dataSource];
  const index = newData.findIndex(item => row.key === item.key);
  const item = newData[index];
  newData.splice(index, 1, { ...item, ...row }); 
  this.setState({
    dataSource: newData,
  });
  this.setState({
    imageUrl: data.url,
    loadingimage: false,
  });

  //this.setState({fileList: this.state.fileList.slice(0,-1)});
 
  //this.setState({fileList: this.state.fileList.concat(pictures)});
})
// this.loadphotos();
  }
  catch (error) {
  // alert(error);
  console.log(error);
  }
}

postdescription = async() => {
   
  try {
  
let collection={};
collection.mdvalue = this.state.value;
collection.htmlvalue = marked(this.state.value);
await fetch('https://apiv2.chennaihost.com/api/blogs/'+this.state.id, {
  method: "put",
    headers: {
        'Authorization': 'Bearer ' + getUser().token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    //make sure to serialize your JSON body
    body: JSON.stringify(collection),
    }
)
.then((response) => {
  if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
})
.then((data) => {
  

  //this.setState({fileList: this.state.fileList.slice(0,-1)});
 
  //this.setState({fileList: this.state.fileList.concat(pictures)});
})
// this.loadphotos();
  }
  catch (error) {
   //alert(error);
   console.log(error);
  }
}

handleEditorChange=(value) =>{   
  
  this.setState({
    value
  })
};



//modal
  render() {
    //editor

  
    ///editor ends
    const { dataSource, loading, imageUrl, loadingimage, addnew, value } = this.state;
    const toolbar= {
      h1: true,
      h2: true,
      h3: true,
      h4: true,
      h5: true,
      h6: true,
      img: true,
      list: true,
      para: {
        paragraph: true,       
        italic: true,
        bold: true,         
        underline: true,         
      },
      table: true,      
      quote: true,      
      link: true,       
      inlinecode: true,  
      code: true,       
      
      preview: true,    
     
      undo: true,
      redo: true,
      
  }
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });


    return (
      <div>
        <Myheader />
        
        <Box p={2}>
        <Paper elevation={3}>

        <Box display='flex' p={2} pb={0}>
          <Box flexGrow={1}>
          <Typography variant="h5" component="h1">
          <SnippetsOutlined style={{ color:'green'}} /> News
          </Typography>
          </Box>
        <Button
          onClick={this.handleAdd}
          color="primary"
          variant='contained'
        >
          {addnew}
        </Button>
        </Box>

        <Box p={1}>
        {loading === "false" ? <div align='center'><Spin size="large" /></div>:<Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          size="small"        
          dataSource={dataSource}
          columns={columns}
        />}

        </Box>
        </Paper>
        </Box>

          <Modal
          title="Click Image to Change"
          visible={this.state.visible}
          bodyStyle={{ textAlign:'center' }}
          onCancel={this.handleCancel}
          footer={[]}
        >
           <Upload
        name="file"
        className="avatar-uploader"
        showUploadList={false}
        action="https://cors-anywhere.herokuapp.com/https://apiv2.chennaihost.com/api/media_objects"
        headers= {{
          authorization: 'Bearer ' + getUser().token,
        }}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
         {loadingimage === true ?<Spin size="large" />: <Tooltip title="Click to Update"><img src={imageUrl} alt="avatar" style={{ width: '100%', cursor:'pointer' }} /></Tooltip>}
        
      </Upload>
        </Modal>
        <Modal
          title="Description"
          visible={this.state.visibled}
          onOk={this.handleOkd}
          onCancel={this.handleCanceld}
          width={1000}
        >
      {loadingimage === true ?<div style={{ textAlign:'center' }}><Spin size="large" /></div>: <Editor ref={this.$vm} toolbar={toolbar} value={value}  
    addImg={($file) => this.addImg($file)} height={400} 
    onChange={value => this.handleEditorChange(value)} highlight={Hljs.highlightAuto} />}
  {/* //     <MdEditor      
  //    value={this.state.value}   
  //   // plugins={['header', 'image']}
  //  plugins={['header', 'fonts', 'table', 'image', 'link', 'clear', 'logger', 'mode-toggle']}
  //    renderHTML={(text) => mdParser.render(text)}
 
  //    onChange={this.handleEditorChange}
  //    onCustomImageUpload={this.onCustomImageUpload}
  //    /> */}
     
        
     </Modal>
        
      </div>
    );
  }
}
