import React from "react"
import { Link, useHistory } from "react-router-dom";
import { handleLogin, isLoggedIn } from "../services/auth"

import { Hidden, IconButton, Button, Paper, Box, Typography, Grid, Container  } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import Bg1 from "../images/bg2.jpg";
import Logo from "../images/logo.png";

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }));

export default function IndexPage() {

    const classes = useStyles();

  let history = useHistory();
  const [email, setEmail] = React.useState('');
  const [result, setResult] = React.useState('resulthide');
  const [loginbutton, setLoginbutton] = React.useState('Login');
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleChange1 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) =>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
   
    if(name==="email")
    {
      setEmail(value);
    }
    
    
    
  }

  const  handleSubmit = (event) => {
    setLoginbutton('Please Wait...')
    let collection={};
    collection.email = email;
    collection.password = values.password;

    let collection1={};
    collection1.email = email;
    
   
    event.preventDefault()
    

    fetch('https://apiv2.chennaihost.com/login_check', {
      method: "post",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(collection),
      })
      .then((resp) => resp.json()) // Transform the data into json
.then(function(data) {
  
  if(data.token)
  {
    collection1.token = data.token;
    handleLogin(collection1)
    history.push(`/app/profile/`);
  }
  else
  {
   setEmail('')
   setValues({ ...values, password: '' });

    setResult('resultshow')
    setLoginbutton('Login')
  }
})
    


  }



  if (isLoggedIn()) {
    history.push(`/app/profile/`);
  }

// password





return (

    <Box>
 <Container>
   <Box pt={4}>
   <Paper>
   <Grid container >
    
     <Grid item xs={12} md={6}>
     <Box p={4} style={{ backgroundColor:'#fff'}}>
                    <Box pt={1} pb={2}>
                    <img src={Logo} alt="africorp" />
                    </Box>

                    <Box pt={1}>
                    <Typography gutterBottom variant="h6">Login into your account </Typography>
                    </Box>
                    <div className={result}><Alert severity="error">Wrong Login Credentials</Alert></div>
        
     
        <ValidatorForm onSubmit={handleSubmit}>

        <TextValidator
                    label="Email"
                    onChange={handleChange}
                    id="email"
                    name="email"
                    value={email}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    validators={['required', 'isEmail']}
                    errorMessages={['Email is required', 'email is not valid']}
                />

        <TextValidator
                    label="Password" 
                    onChange={handleChange1('password')}
                    id="password" 
                    name="password"
                    value={values.password}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                }}
                    
                    validators={['required']}
                    errorMessages={['Password is required']}
                />

         
       
          
           <Box pt={2} pb={2}>
           <Button variant="contained" type="submit" color="secondary"  size="large">
  {loginbutton}
</Button>
       </Box>
       </ValidatorForm>
       <Box pt={1}>
          <Typography>
          Forgot password? 
          <Link to="/forgot-password/">Reset</Link>
          </Typography>
          </Box>

             </Box>
     </Grid>
   
     <Grid item xs={12} md={6}>
   <Hidden smDown>
     <Box p={1} pl={0}>
       <Box>
       <img src={Bg1} alt="africorp" className={classes.img} />
       </Box>
       </Box>
       </Hidden>
     </Grid>


   </Grid>

   </Paper>
   </Box>

  
 </Container>
 </Box>

);
}
