import React from "react"
import { getUser } from "../services/auth"
import Grid from '@material-ui/core/Grid';

import Myheader from "./myheader"

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import Alert from '@material-ui/lab/Alert';

import {
    EyeInvisibleOutlined,
} from '@ant-design/icons';

const proxyurl = "https://cors-anywhere.herokuapp.com/";
export default function Changepassword() {

  const [result, setResult] = React.useState('resulthide');
  const [resultmessage, setResultmessage] = React.useState('');
  const [loginbutton, setLoginbutton] = React.useState('Change Password');
  const [values, setValues] = React.useState({
    currentpassword: '',
    newpassword: '',
    confirmpassword: '',
    showPassword: false,
  });

  const handleChange1 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

 

  const  handleSubmit = (event) => {
    if(values.newpassword === values.confirmpassword)
    {
    setLoginbutton('Please Wait...')
    let collection={};
    collection.email = getUser().email;
    collection.currentpassword = values.currentpassword;
    collection.newpassword = values.newpassword;

    
   
    event.preventDefault()
    

    fetch(proxyurl + 'https://apiv2.chennaihost.com/africorp/changepassword', {
      method: "post",
      headers: {
          'Authorization': 'Bearer ' + getUser().token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(collection),
      })
      .then((resp) => resp.json()) // Transform the data into json
.then(function(data) {
  setResult('resultshow');
  setResultmessage(data.result);
  setValues({ ...values, currentpassword: '', newpassword: '', confirmpassword: ''});
  setLoginbutton('Change Password')
})
    
    }
    else
    {
      setResult('resultshow');
      setResultmessage('New Password and Confirm password is not matching');
    }

  }

 
  return (
      <div>
        <Myheader />
{/* <Paper>
<Box bgcolor="grey.200">
    <Grid container >

    <Grid item xs={12}>
      <Box p={1}>
    <Typography variant="h5" component="h1">
    <EyeInvisibleOutlined style={{ color:'green'}} /> Change Password
    </Typography>
    </Box>

    </Grid>

    </Grid>
    </Box>
    </Paper> */}


    <Grid container>
        <Grid item md={3}></Grid>
    <Grid item xs={12} md={6}>
    <Box p={2}>
        <Paper elevation={3}>
      <Box p={2} pb={0}>
    <Typography variant="h5" component="h1">
    <EyeInvisibleOutlined style={{ color:'green'}} /> Change Password
    </Typography>
    </Box>

        <Box p={2} pt={1}>
        <div className={result}><Alert severity="info">{resultmessage}</Alert></div>
        <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
                    label="Password" 
                    onChange={handleChange1('currentpassword')}
                    id="currentpassword" 
                    name="currentpassword"
                    value={values.currentpassword}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                }}
                    
                    validators={['required']}
                    errorMessages={['Current Password is required']}
                />
         <TextValidator
                    label="New Password" 
                    onChange={handleChange1('newpassword')}
                    id="newpassword" 
                    name="newpassword"
                    value={values.newpassword}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                }}
                    
                    validators={['required']}
                    errorMessages={['New Password is required']}
                />
                  <TextValidator
                    label="Confirm Password" 
                    onChange={handleChange1('confirmpassword')}
                    id="confirmpassword" 
                    name="confirmpassword"
                    value={values.confirmpassword}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                }}
                    
                    validators={['required']}
                    errorMessages={['Confirm Password is required']}
                />
        <Box pt={2}>
        <Button variant="contained" type="submit" color="secondary"  size="large">
  {loginbutton}
</Button>

</Box>
</ValidatorForm>

    </Box>
      </Paper>
      </Box>
    
    
    </Grid>
    <Grid item md={3}></Grid>

    </Grid>
    </div>
);
}
