import React from 'react';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import PrivateRoute from "./components/privateRoute";
import Index from './pages/index';
import ForgotPassword from './pages/forgotPassword';
import Profile from "./components/profile"
import Products from "./components/products"
import News from "./components/news"
import Cpassword from "./components/cpassword"
export default function App() {
  return (
    <Router>
    <div>
   
     <Switch>
    <Route path="/" exact component ={Index} />
    <Route path="/forgot-password/" component ={ForgotPassword} />
    <PrivateRoute path="/app/profile/" component={Profile} />
      <PrivateRoute path="/app/products/" component={Products} />
      <PrivateRoute path="/app/news/" component={News} />
      <PrivateRoute path="/app/cpassword/" component={Cpassword} />
     </Switch>
    </div>
    </Router>
  )
}
