import React from "react"
import { useHistory } from "react-router-dom";
import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
    let history = useHistory();
  if (!isLoggedIn() && location.pathname !== `/`) {
    history.push(`/`);
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute