import React from "react"// Tell Webpack this JS file uses this image
import { Link, useHistory } from "react-router-dom";
import { isLoggedIn } from "../services/auth"

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Bg1 from "../images/bg2.jpg";
import Logo from "../images/logo.png";

import { makeStyles } from '@material-ui/core/styles';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles(theme => ({
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }));

export default function ForgotPage() {

    const classes = useStyles();

    let history = useHistory();

  const [email, setEmail] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [result, setResult] = React.useState('resulthide');
  const [resultmessage, setResultmessage] = React.useState('');
  const [boxs, setBoxs] = React.useState('resultshow');
  const [boxh, setBoxh] = React.useState('resulthide');
  const [loginbutton, setLoginbutton] = React.useState('Reset Password');
  const [otpmessage, setOtpmessage] = React.useState('Send OTP to Email');
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleChange1 = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) =>{
    const target = event.target;
    const value = target.value;
    const name = target.name;
   
    if(name==="email")
    {
      setEmail(value);
    }
    if(name==="otp")
    {
      setOtp(value);
    }
    
    
  }

  const  handleSubmit = (event) => {
    setLoginbutton('Please Wait...')
    let collection={};
    collection.email = email;
    collection.otp = otp;
    collection.password = values.password;
    
   
    event.preventDefault()
    

    fetch('https://apiv2.chennaihost.com/site/reset', {
      method: "post",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(collection),
      })
      .then((resp) => resp.json()) // Transform the data into json
.then(function(data) {
  setResult('resultshow');
  setResultmessage(data.result);
  setEmail('');
   setValues({ ...values, password: '' });
   setOtp('');
   setLoginbutton('Reset Password')
    
})
    


  }

    const  handleOtp = (event) => {
      setBoxs('resulthide');
      setBoxh('resultshow');
      setOtpmessage('Wait ....');
    let collection={};
    collection.email = email;
    event.preventDefault()
    

    fetch('https://apiv2.chennaihost.com/site/scode', {
      method: "post",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },

      //make sure to serialize your JSON body
      body: JSON.stringify(collection),
      })
      .then((resp) => resp.json()) // Transform the data into json
.then(function(data) {
  setOtpmessage(data.result);
  
})
    


  }



  if (isLoggedIn()) {
    history.push(`/app/profile/`);
  }


  return (

    <Container>
   <Box pt={4}>
   <Paper>
   <Grid container >
    
     <Grid item xs={12} md={6}>
     <Box p={4} style={{ backgroundColor:'#fff'}}>
                    <Box pt={1} pb={2}>
                    <img src={Logo} alt="africorp" />
                    </Box>

           <ValidatorForm onSubmit={handleSubmit}>
<div className={result}><Alert severity="info">{resultmessage}</Alert></div>
        <TextValidator
                    label="Email"
                    onChange={handleChange}
                    id="email"
                    name="email"
                    value={email}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    validators={['required', 'isEmail']}
                    errorMessages={['Email is required', 'email is not valid']}
                />

        <TextValidator
                    label=" New Password" 
                    onChange={handleChange1('password')}
                    id="password" 
                    name="password"
                    value={values.password}
                    variant="outlined"
                    type={values.showPassword ? 'text' : 'password'}
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                }}
                    
                    validators={['required']}
                    errorMessages={['Password is required']}
                />


      

             <TextValidator
                    label="OTP"
                    onChange={handleChange}
                    name="otp"
                    value={otp}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
         
         <Box pt={1} className={boxs}>
          <Typography onClick={handleOtp} style={{ cursor:'pointer', color:'#1890ff' }}>
          {otpmessage} 
          </Typography>
          </Box>
          <Box pt={1} className={boxh}>
          <Typography>
          {otpmessage} 
          </Typography>
          </Box>

          
      
          
           <Box pt={2} pb={2}>
        <Button variant="contained"  type="submit" color="secondary" size="large" style={{ marginRight:7 }}>
      {loginbutton}
       </Button>
       <Link to="/">
       <Button variant="contained" color="primary" size="large">
       Cancel
       </Button>
       </Link>
       </Box>

        </ValidatorForm>

             </Box>
     </Grid>
     
     <Grid item xs={12} md={6}>
   
     <Box p={1} pl={0}>
       <Box>
       <img src={Bg1} alt="africorp" className={classes.img} />
       </Box>
       </Box>
     </Grid>

   </Grid>

   </Paper>
   </Box>
 </Container>

);
}