import React from 'react';

import { getUser, logout } from "../services/auth";

import { Link, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';

import Mobilemenu from "./mobilemenu"

// import Logo from "../images/logo.png"

import { Menu, Dropdown, Avatar, Drawer } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {
    ContainerOutlined,
    SnippetsOutlined,
    FileImageOutlined,
} from '@ant-design/icons';
// logout dropdown




const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    color:'#fff',
  },
   // necessary for content to be below app bar
   toolbar: theme.mixins.toolbar,

  menu: {
    backgroundColor:'#0e359d',
    border:'none',
    color:'#fff',
    marginRight: theme.spacing(2),
    fontSize:16,
  },
  log: {
    cursor:'pointer',
  },
}));

export default function Myheader() {
  const classes = useStyles();
let history=useHistory();
  const [visible, setVisible] = React.useState(false);
const activemenu = typeof window !== 'undefined' ? window.location.pathname : '';
  //const activemenu = "/app/profile/";


  
  const showDrawer = () => {
    setVisible(true);
   };

  const onClose = () => {
    setVisible(false);
  };
  const menu = (

    <Menu>
    <Menu.Item key="0">
      <Link to="/app/cpassword/">Change Password</Link>
    </Menu.Item>
   
    <Menu.Divider />
    <Menu.Item key="3">  <a
            href="/"
            onClick={event => {
              event.preventDefault()
              logout(() => history.push(`/`))
            }}
          >Logout </a>

          </Menu.Item>
  </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{ backgroundColor:'#0e359d', zIndex:11 }}>
        <Toolbar>
            <Hidden mdUp>
        <IconButton className={classes.menuButton} color="inherit" edge="start" onClick={showDrawer}>
        <MenuIcon />
        </IconButton>
        </Hidden>
        <Drawer
          title="AfriCorp"
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ backgroundColor:'#001529', padding:0 }}
          width={200}
        >
          <Mobilemenu/>
        </Drawer>

          <Typography variant="h6" className={classes.title}>
          AfriCorp
          </Typography>
          
         <Hidden smDown>
             {/* menu starts here */}
          <Menu mode="horizontal" selectedKeys={[activemenu]} className={classes.menu}>
        <Menu.Item key="/app/profile/">
        <Link to="/app/profile/" style={{ color:'#fff' }}>
          <FileImageOutlined />
         Slider</Link> 
        </Menu.Item>

        <Menu.Item key="/app/products/">
        <Link to="/app/products/" style={{ color:'#fff' }}>
          <ContainerOutlined /> Products</Link>
        </Menu.Item>

        <Menu.Item key="/app/news/"> 
          <Link to="/app/news/" style={{ color:'#fff' }}>
          <SnippetsOutlined /> News</Link>
        </Menu.Item>

        </Menu>
        </Hidden>
        {/* menu ends here */}
  <Dropdown overlay={menu} trigger={['click']} className={classes.log}>
    <Typography  onClick={e => e.preventDefault()}>
  <Avatar style={{ backgroundColor: '#1890ff',textTransform:'uppercase' }}>{getUser().firstLetter}</Avatar>
    </Typography>
  </Dropdown>

        </Toolbar>
      </AppBar>

   
        <div className={classes.toolbar} />

    </div>
  );
}

