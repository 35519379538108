import React, { useContext, useState, useEffect, useRef } from 'react';

import { useHistory } from "react-router-dom";

import { getUser, logout } from "../services/auth";

import Myheader from "./myheader"

import 'antd/dist/antd.css';
import { Table, Input, Popconfirm, Form, Modal, Upload, message, Spin, Tooltip} from 'antd';
import DeleteIcon from '@material-ui/icons/Delete';

import { Paper, Button, Box, Typography, IconButton } from '@material-ui/core';
import {
  FileImageOutlined,
} from '@ant-design/icons';


function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  // return isJpgOrPng && isLt2M;
 return true;
}


const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
    
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default class Profile extends React.Component {
    
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'key',
        width: '40px',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.key - b.key,
   
      },
      {
        title: 'Name',
        dataIndex: 'name',
        editable: true,
    sorter: (a, b) => { return a.name.localeCompare(b.name)},
   
      },
      
      {
        title: 'Image',
        dataIndex: 'image',
        width: '70px',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
     
              <Button onClick={() =>this.showModal(record.key)} ><img src={text} alt="slider" style={{ width:40, height:40 }} /></Button>
          
            
          ) : null,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: '80px',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
            <IconButton aria-label="delete" color="secondary">
  <DeleteIcon />
</IconButton>
          </Popconfirm>
          ) : null,
          
      },
    ];
    this.state = {
      dataSource: [],
      count: 2,
      id: 0,
      loading: "false",
      visible: false,
      loading1: false,
      loadingimage: false,
      addnew: "Add New",
    };
  }
  loadphotos = async () =>
  {
  
   try {
     await fetch('https://apiv2.chennaihost.com/api/sliders', {
       method: 'GET',
       headers: {
        'Authorization': 'Bearer ' + getUser().token,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
       },
     })
     .then((response) => {
      
      if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
      
      
     })
     .then((data) => {
      
      this.setState({loading:"true"})
       this.setState({dataSource: data.map(item => ({
         ...item,
         key: item.id,
         name: item.name,
         image: item.url,
       })), count:data.length});
     })
   }
   catch (error) {
    //  alert(error);
    console.log(error);
   }
  }
   componentDidMount = ()=>{
     this.loadphotos();
  
 }
  handleDelete = key => {
    
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter(item => item.key !== key),
    });

    let deleteapi='https://apiv2.chennaihost.com/api/sliders/' + key;
    fetch(
      deleteapi,{
        method: "DELETE",
        headers: {
            'Authorization': 'Bearer ' + getUser().token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        }
    );

  };

  handleAdd = async () => {
    this.setState({addnew: "Adding .. "})
    try {
      let collection={};
      collection.name = "click to edit";
      collection.url = "https://www.chennaihost.com/images/noimage.jpg";
      await fetch('https://apiv2.chennaihost.com/api/sliders', {
        method: "post",
          headers: {
              'Authorization': 'Bearer ' + getUser().token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          //make sure to serialize your JSON body
          body: JSON.stringify(collection),
          }
      )
      .then((response) => {
        if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
      })
      .then((data) => {
        const { count, dataSource } = this.state;
        const newData = {
          key: data.id,
          name: data.name,
          image: data.url,
        };
        this.setState({
          dataSource: [...dataSource, newData],
          count: count + 1,
          addnew: "Add New",
        });
    
        
      })
      // this.loadphotos();
        }
        catch (error) {
         //alert(error);
         console.log(error);
        }

   
  };

  handleSave = row => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row }); 
    this.setState({
      dataSource: newData,
    });
    const newitems = newData[index];
    try {
      let collection={};
      collection.name = newitems.name;
      fetch('https://apiv2.chennaihost.com/api/sliders/'+newitems.key, {
        method: "put",
          headers: {
              'Authorization': 'Bearer ' + getUser().token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          //make sure to serialize your JSON body
          body: JSON.stringify(collection),
          }
      )
      .then((response) => {
        if(response.status === 401)
        {
          logout(() => useHistory().push(`/`));
          
        }
        else
        {
          return response.json();
        }
      })
      .then((data) => {
       
    
        
      });
      // this.loadphotos();
        }
        catch (error) {
        // alert(error);
        console.log(error);
        }
    //alert (newitems.name);
  };
// modal
showModal = key => {
  
  this.setState({
    visible: true,
    loadingimage: true,
    id: key,
  });
  let getapi='https://apiv2.chennaihost.com/api/sliders/' + key;
    fetch(
      getapi,{
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + getUser().token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          
          if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
        })
        .then((data) => {

         this.setState({imageUrl:data.url, loadingimage:false})
        
        })

};

handleOk = e => {
  console.log(e);
  this.setState({
    visible: false,
  });
};

handleCancel = e => {
  console.log(e);
  this.setState({
    visible: false,
  });
};
handleChange = info => {

  if (info.file.status === 'uploading') {
    this.setState({ loadingimage: true });
    return;
  }
  if (info.file.status === 'done') {
    this.postphotos(info.file.response.contentUrl);
  }
};

postphotos = async(url) => {
   
  try {
  
let collection={};
collection.url = "https://apiv2.chennaihost.com/" + url;
await fetch('https://apiv2.chennaihost.com/api/sliders/'+this.state.id, {
  method: "put",
    headers: {
        'Authorization': 'Bearer ' + getUser().token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    //make sure to serialize your JSON body
    body: JSON.stringify(collection),
    }
)
.then((response) => {
  if(response.status === 401)
      {
        logout(() => useHistory().push(`/`));
        
      }
      else
      {
        return response.json();
      }
})
.then((data) => {
  let row={};
  row.key = this.state.id;
  row.image = data.url;
  row.name = data.name;
  const newData = [...this.state.dataSource];
  const index = newData.findIndex(item => row.key === item.key);
  const item = newData[index];
  newData.splice(index, 1, { ...item, ...row }); 
  this.setState({
    dataSource: newData,
  });
  this.setState({
    imageUrl: data.url,
    loadingimage: false,
  });

  //this.setState({fileList: this.state.fileList.slice(0,-1)});
 
  //this.setState({fileList: this.state.fileList.concat(pictures)});
})
// this.loadphotos();
  }
  catch (error) {
   //alert(error);
   console.log(error);
  }
}

//modal
  render() {
    const { dataSource, loading, imageUrl, loadingimage, addnew } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

 
    return (
      <div>
<Myheader />
{/* <Paper>
<Box bgcolor="grey.200">
    <Grid container>

    <Grid item xs={12}>
          <Box display='flex' p={1}>
          <Box flexGrow={1}>
          <Typography variant="h5" component="h1">
          <FileImageOutlined style={{ color:'green'}} /> Slider
          </Typography>
          </Box>
        <Button
          onClick={this.handleAdd}
          color="primary"
          variant='contained'
        >
          {addnew}
        </Button>
        </Box>
        </Grid>

</Grid>
</Box>
</Paper> */}
       <Box p={2}>
        <Paper elevation={3}>

        <Box display='flex' p={2} pb={0}>
          <Box flexGrow={1}>
          <Typography variant="h5" component="h1">
          <FileImageOutlined style={{ color:'green'}} /> Slider
          </Typography>
          </Box>
        <Button
          onClick={this.handleAdd}
          color="primary"
          variant='contained'
        >
          {addnew}
        </Button>
        </Box>
        
         <Box p={2}>
        {loading === "false" ? <div align='center'><Spin size="large" /></div>:<Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered 
          size="small"      
          dataSource={dataSource}
          columns={columns}
        />}
        </Box>
        </Paper>
        </Box>

          <Modal
          title="Click Image to Change"
          visible={this.state.visible}
          bodyStyle={{ textAlign:'center' }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[]}
        >
           <Upload
        name="file"
        className="avatar-uploader"
        showUploadList={false}
        action="https://cors-anywhere.herokuapp.com/https://apiv2.chennaihost.com/api/media_objects"
        headers= {{
          authorization: 'Bearer ' + getUser().token,
        }}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
         {loadingimage === true ?<Spin size="large" />: <Tooltip title="Click to Update"><img src={imageUrl} alt="avatar" style={{ width: '100%', cursor:'pointer' }} /></Tooltip>}
        
      </Upload>
        </Modal>
        
      </div>
    );
  }
}
